import React from "react";
import { motion } from "framer-motion"


function Container({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="my-20 md:my-20 mx-10 md:mx-32 text-white font-Poppins  ">{children}</div>
    </motion.div>
  )
}


export default Container;
