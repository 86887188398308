import React from "react";
import { Link } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";

function ProjectCard({ imgPath, name, description, link }) {
  // console.log(imgPath)
  return (
    <div class="card p-2 my-2 bg-none text-center shadow-md shadow-purple-500 hover:shadow-green-600 hover:scale-105 transition-all duration-500 rounded-lg mx-auto md:mx-4 flex flex-col justify-around">
      <img src={imgPath} alt="image" className="mx-auto" />
      <div class=" text-2xl font-bold">{name}</div>
      <p>

        {description}</p>

      <Link
        to={{
          pathname:
            "",
        }}
        target="_blank"

      >
        <a target="_blank" href={link} className="bg-purple-600 hover:bg-green-700 transition-all duration-300 text-xl p-1 rounded flex justify-center text-center md:mx-5 text-xl mt-2" >
          <span>
            {" "}
            <FiExternalLink className="mt-[3px]" />{" "}
          </span>
          See Project
        </a>
      </Link>
    </div>
  );
}

export default ProjectCard;
