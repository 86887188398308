import React from "react";
import Container from "../Layout/Container";
import { FiLinkedin,FiMail, FiGithub } from "react-icons/fi";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Container>

      <footer className="text-center">
        <section className="find-me h-[200px]  w-full mt-10">
          <div className="my-links text-center">
            <span className="text-3xl md:text-5xl font-Raleway">FIND ME ON </span>
            <p className="text-xl ">Feel free to connect with me.</p>
            <div className="socials gap-4 mt-4 text-center flex justify-center">
              <Link
                to=""
                target="_blank"
                className="p-2 bg-white rounded-full shadow-yellow-400 shadow-md hover:shadow-red-400"
              >
                <a href="https://www.linkedin.com/in/tauleshwar/">
                  <FiLinkedin className=" text-4xl text-[#1b1429]  " />
                </a>
              </Link>
              <Link
                to=""
                target="_blank"
                className="p-2 bg-white rounded-full shadow-yellow-400 shadow-md hover:shadow-red-400"
              >
                <a href="https://github.com/tauleshwar/">
                  <FiGithub className=" text-4xl text-[#1b1429]  " />
                </a>
              </Link>
              <Link
                to=""
                target="_blank"
                className="p-2 bg-white rounded-full shadow-yellow-400 shadow-md hover:shadow-red-400"
              >
                <a href="mailto:tauleshwarthakur@gmail.com">
                  <FiMail className=" text-4xl text-[#1b1429]  " />
                </a>
              </Link>

            </div>
          </div>
        </section>
        <div className="tech">Built with React, Tailwind and 💝</div>
        {/* <span className="text-gray-500 ">Design insipiration <a href="https://www.soumya-jit.tech/" className="text-green-200">Soumyajit</a></span> */}
      </footer>
    </Container>
  );
}

export default Footer;
